<template>
  <div class="main">
    <!-- The iframe where the PDF will be shown -->
    <div style="display:flex;flex-direction: column;gap: 10px;width:100%">
      <router-link
        :to="returnPath"
      >
        <button
          class="button"
        >
          {{ $t('Return') }}
        </button>
      </router-link>
      <iframe
        v-if="pdfBlobUrl"
        :src="pdfBlobUrl"
        style="width: 100%; height: 100vh;"
        frameborder="0"
      />
      <p v-else>
        No PDF available for preview.
      </p>
    </div>


  </div>
</template>

<script>
export default {
  data() {
    return {
      pdfBlobUrl: null,
    };
  },
  computed: {
    returnPath() {
      return sessionStorage.getItem('pathName') || '/';
    },
  },
  mounted() {
    // Retrieve the Blob URL from sessionStorage
    this.pdfBlobUrl = sessionStorage.getItem('pdfBlobUrl');

    if (!this.pdfBlobUrl) {
      console.error('No PDF Blob URL found in sessionStorage.');
    }

    // Optional: Clear the Blob URL from sessionStorage to free up memory
    sessionStorage.removeItem('pdfBlobUrl');
    sessionStorage.removeItem('pathName');
  },
};
</script>

<style>
</style>
